import {TextField} from '@material-ui/core';
import React, {useEffect, useState} from 'react';
import {
    useHistory,
    useParams
} from "react-router-dom";
import {makeStyles} from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Axios from "axios";

interface IItemData {
    is_exported: string,
    is_extracted: string,
    s3_key_name: string,
    extracted_date: string,
    doc_type: string,
    id: string,
    is_verified: string,
    s3_key: string,
    title: string,
    upload_date: string
}

interface ITableItem {
    id: string,
    A: String,
    B: String,
    C: String,
    D: String,
    E: String,
    F: String,
    G: String
}

interface ExtractedData {
    pages: ITableItem[]
}

interface IResponseData {
    item: IItemData,
    extracted_data: ExtractedData
}

const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },
});

function createData(name: String, calories: Number, fat: Number, carbs: Number, protein: Number) {
    return {name, calories, fat, carbs, protein};
}

const rows = [
    createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
    createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
    createData('Eclair', 262, 16.0, 24, 6.0),
    createData('Cupcake', 305, 3.7, 67, 4.3),
    createData('Gingerbread', 356, 16.0, 49, 3.9),
];


export const ItemDetails = () => {
    const history = useHistory();
    const {s3_key} = useParams<{ s3_key: string }>();
    const [itemData, setItemData] = useState<IItemData>();
    const [extractedData, setExtractedData] = useState<ExtractedData>();
    const [tableItem, setITableItem] = useState<ITableItem[]>([]);

    const file_name = s3_key.split(".")[0];
    const doc_type = file_name.split("__")[1];

    const Apicall = async () => {
        const responses = await Axios.get('https://k7drmwj8u0.execute-api.us-east-1.amazonaws.com/default', {
            params: {s3_key: s3_key}
        })
        if (responses.status == 200) {
            const parseData = responses.data
            const _iResponseData = parseData as IResponseData
            setItemData(_iResponseData.item)
            setExtractedData(_iResponseData.extracted_data)
            var pages = _iResponseData.extracted_data.pages
            setITableItem(pages)
        }
    }

    useEffect(() => {
        Apicall();
    }, []);

    const classes = useStyles();

    var tableItemAsJson: any = []

    tableItem.forEach((eachItem) => {
        tableItemAsJson.push({
            "tempId": eachItem.id,
            "doc_type": doc_type,
            "is_exported": "0",
            "id": eachItem.id,
            "A": eachItem.A,
            "B": eachItem.B,
            "C": eachItem.C,
            "D": eachItem.D,
            "E": eachItem.E,
            "F": eachItem.F,
            "G": eachItem.G
        })
    });

    const s3Url = 'https://time-tracking-2.s3.amazonaws.com/' + s3_key;

    const handleSubmit = async (event: React.FormEvent<HTMLButtonElement>) => {
        event.preventDefault();
        const responses = await Axios.post('https://tk5snkaoek.execute-api.us-east-1.amazonaws.com/default', {
            verified_table_data: tableItemAsJson,
            doc_type: itemData?.doc_type,
            s3_key: s3_key
        })
        if (responses.status == 200) {
            alert('Save successfully.');
        }
    };

    const handleAddNewRow = async (event: React.FormEvent<HTMLButtonElement>) => {
        event.preventDefault();
        const dateTime = Date.now()
        const s3_key_t = dateTime + "__" + doc_type;

        const newRow = {
            tempId: s3_key_t,
            doc_type: doc_type,
            is_exported: '0',
            id: s3_key_t,
            "A": '',
            "B": '',
            "C": '',
            "D": '',
            "E": '',
            "F": '',
            "G": ''
        }
        tableItemAsJson.push(newRow);
        setITableItem(tableItemAsJson)
    };

    const updateTextValue = (newValue: string, id: string, column: string) => {
        for (let eachItem of tableItemAsJson) {
            if (eachItem.id === id) {
                if (column === 'A') {
                    eachItem.A = newValue;
                    setITableItem(tableItemAsJson)
                } else if (column === 'B') {
                    eachItem.B = newValue
                    setITableItem(tableItemAsJson)
                } else if (column === 'C') {
                    eachItem.C = newValue
                    setITableItem(tableItemAsJson)
                } else if (column === 'D') {
                    eachItem.D = newValue
                    setITableItem(tableItemAsJson)
                } else if (column === 'E') {
                    eachItem.E = newValue
                    setITableItem(tableItemAsJson)
                } else if (column === 'F') {
                    eachItem.F = newValue
                    setITableItem(tableItemAsJson)
                } else if (column === 'G') {
                    eachItem.G = newValue
                    setITableItem(tableItemAsJson)
                }
            }
        }
    }

    return (
        <div className="flex space-x-4">
            <div className="w-1/2">
                <iframe
                    style={{width: "100%", height: "100%"}}
                    src={s3Url}
                />
            </div>
            <div className="w-1/2">
                <h1 className="text-2xl font-semibold mb-6">Verify Form</h1>
                <form>
                    <TextField name="title" label="Title" fullWidth value={itemData?.title}/>
                    <TableContainer component={Paper}>
                        <Table className={classes.table} aria-label="simple table">
                            <TableBody>
                                {
                                    tableItem.map((row) => (
                                        <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                                          <Table className={classes.table} aria-label="simple table">
                                            <TableBody>
                                              <TableRow hover role="checkbox" tabIndex={-1} key={'in-'+row.id}>
                                                <TableCell>!TIMEACT</TableCell>
                                                <TableCell component="th" scope="row" id={row.id}>
                                                  <TextField value={row.A} style={{ width:'100%' }}
                                                             onChange={e => updateTextValue(e.target.value, row.id, 'A')}/>
                                                </TableCell>
                                              </TableRow>
                                              <TableRow hover role="checkbox" tabIndex={-1} key={'in-'+row.id}>
                                                <TableCell>DATE</TableCell>
                                                <TableCell scope="row">
                                                  <TextField id={row.id} style={{ width:'100%' }}
                                                             value={row.B}
                                                             onChange={(e) => updateTextValue(e.target.value, row.id, 'B')}/>
                                                </TableCell>
                                              </TableRow>
                                              <TableRow hover role="checkbox" tabIndex={-1} key={'in-'+row.id}>
                                                <TableCell>JOB</TableCell>
                                                <TableCell scope="row">
                                                  <TextField id={row.id} style={{ width:'100%' }}
                                                             value={row.C}
                                                             onChange={(e) => updateTextValue(e.target.value, row.id, 'C')}/>
                                                </TableCell>
                                              </TableRow>
                                              <TableRow hover role="checkbox" tabIndex={-1} key={'in-'+row.id}>
                                                <TableCell>EMP</TableCell>
                                                <TableCell scope="row">
                                                  <TextField id={row.id} style={{ width:'100%' }}
                                                             value={row.D}
                                                             onChange={(e) => updateTextValue(e.target.value, row.id, 'D')}/>
                                                </TableCell>
                                              </TableRow>
                                              <TableRow hover role="checkbox" tabIndex={-1} key={'in-'+row.id}>
                                                <TableCell>ITEM</TableCell>
                                                <TableCell scope="row">
                                                  <TextField id={row.id} style={{ width:'100%' }}
                                                             value={row.E}
                                                             onChange={(e) => updateTextValue(e.target.value, row.id, 'E')}/>
                                                </TableCell>
                                              </TableRow>
                                              <TableRow hover role="checkbox" tabIndex={-1} key={'in-'+row.id}>
                                                <TableCell>PITEM</TableCell>
                                                <TableCell scope="row">
                                                  <TextField id={row.id} style={{ width:'100%' }}
                                                             value={row.F}
                                                             onChange={(e) => updateTextValue(e.target.value, row.id, 'F')}/>
                                                </TableCell>
                                              </TableRow>
                                              <TableRow hover role="checkbox" tabIndex={-1} key={'in-'+row.id}>
                                                <TableCell>DURATION</TableCell>
                                                <TableCell scope="row">
                                                  <TextField id={row.id} style={{ width:'100%' }}
                                                             value={row.G}
                                                             onChange={(e) => updateTextValue(e.target.value, row.id, 'G')}/>
                                                </TableCell>
                                              </TableRow>
                                              <TableRow hover role="checkbox" tabIndex={-1} key={'in-'+row.id}>
                                                <TableCell colSpan={2}>
                                                  <hr/>
                                                </TableCell>
                                              </TableRow>
                                            </TableBody>
                                          </Table>
                                        </TableRow>
                                    ))
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <button onClick={handleSubmit}
                            className="bg-gray-800 text-white font-semibold rounded-md hover:bg-gray-900 px-3 py-2 my-6">
                        Save
                    </button>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <button onClick={handleAddNewRow}
                            className="bg-gray-800 text-white font-semibold rounded-md hover:bg-gray-900 px-3 py-2 my-6">
                        Add new row
                    </button>
                </form>
            </div>
        </div>
    );
};
