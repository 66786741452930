import React from 'react';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import {Footer} from './components/footer';
import {Navbar} from './components/navbar';
import {Dashboard} from './pages/dashboard';
import {Home} from './pages/home';
import {NoMatch} from './pages/NoMatch';
import {Signin} from './pages/signin';
import {Signup} from './pages/signup';
import Amplify, {Auth} from 'aws-amplify';
import config from './config.json';
import Storage from '@aws-amplify/storage'

const App = () => {
    Amplify.configure({
        Auth: {
            mandatorySignId: true,
            region: config.cognito.REGION,
            userPoolId: config.cognito.USER_POOL_ID,
            userPoolWebClientId: config.cognito.APP_CLIENT_ID,
            identityPoolId: config.AWSS3.IDENTITY_POOL_ID
        },
        Storage: {
            bucket: config.AWSS3.BUCKET,
            region: config.AWSS3.REGION,
            identityPoolId: config.AWSS3.IDENTITY_POOL_ID
        },

        API: {
            endpoints: [
                {
                    name: "GetUnverifiedItemsAPI",
                    endpoint: "https://7524vw8eci.execute-api.us-east-1.amazonaws.com/default",
                    apiKey: "F1QqE1ag1b5Yojttm0ze17s4c3vWp64OhYE94yY2"

                },
                {
                    name: "GetExportItems-API",
                    endpoint: "https://a2h9qlq3ci.execute-api.us-east-1.amazonaws.com/default/GetExportItems",
                    apiKey: "Qm5nIuK9rO7NCYZx7bdfE8XjKrh3epuq597CKnMc"

                }
            ]
        }
    })
    Storage.configure({
        CustomPrefix: {
            public: ''
        },
    })
    Auth.configure();
    return (
        <Router>
            <div className="antialiased h-screen flex flex-col">
                <Navbar/>
                <Switch>
                    <Route exact path="/" component={Home}/>
                    <Route exact path="/signin" component={Signin}/>
                    <Route exact path="/signup" component={Signup}/>
                    <Route path="/dashboard" component={Dashboard}/>
                    <Route path="*">
                        <NoMatch/>
                    </Route>
                </Switch>
                <Footer/>
            </div>
        </Router>
    );
};

export default App;
