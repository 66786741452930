import React from 'react';
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import {TextField} from '@material-ui/core';
import {CSVLink} from "react-csv";
import Axios from "axios";

interface Data {
    title: string;
    date: string;
    doc_type: string;
    is_exported: string;
    uuid: string;
    A: string;
    B: string;
    C: string;
    D: string;
    E: string;
    F: string;
    G: string;
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

type Order = 'asc' | 'desc';

function getComparator<Key extends keyof any>(order: Order, orderBy: Key,): (a: { [key in Key]: number | string }, b: { [key in Key]: number | string }) => number {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
    const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

interface HeadCell {
    disablePadding: boolean;
    id: keyof Data;
    label: string;
    numeric: boolean;
}

const headCells: HeadCell[] = [
    {id: 'A', numeric: true, disablePadding: false, label: '!TIMERHDR'},
    {id: 'B', numeric: true, disablePadding: false, label: 'VER'},
    {id: 'C', numeric: true, disablePadding: false, label: 'REL'},
    {id: 'D', numeric: true, disablePadding: false, label: 'COMPANYNAME'},
    {id: 'E', numeric: true, disablePadding: false, label: 'IMPORTEDBEFORE'},
    {id: 'F', numeric: true, disablePadding: false, label: 'FROMTIMER'},
    {id: 'G', numeric: true, disablePadding: false, label: 'COMPANYCREATETIME'},
];

interface EnhancedTableHeadProps {
    classes: ReturnType<typeof useStyles>;
    onRequestSort: (event: React.MouseEvent<unknown>, property: keyof Data) => void;
    order: Order;
    orderBy: string;
}

function EnhancedTableHead(props: EnhancedTableHeadProps) {
    const {classes, order, orderBy, onRequestSort} = props;
    const createSortHandler = (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? 'right' : 'left'}
                        padding={'default'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        table: {
            minWidth: 750,
        },
        visuallyHidden: {
            border: 0,
            clip: 'rect(0 0 0 0)',
            height: 1,
            margin: -1,
            overflow: 'hidden',
            padding: 0,
            position: 'absolute',
            top: 20,
            width: 1,
        },
    }),
);


interface ExportingTableProps {
    data: Data[];
}

export const ExportingTable = (props: ExportingTableProps) => {
    const {data} = props;
    var exported_ids: string[] = []

    var csvHeaders = [
        {label: "!TIMERHDR", key: "A"},
        {label: "VER", key: "B"},
        {label: "REL", key: "C"},
        {label: "COMPANYNAME", key: "D"},
        {label: "IMPORTEDBEFORE", key: "E"},
        {label: "FROMTIMER", key: "F"},
        {label: "COMPANYCREATETIME", key: "G"},
    ]

    function uuidv4() {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    }

    function getFormattedToday() {
        var d = new Date(),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return month + '/' + day + '/' + year
    }

    const todayStr = getFormattedToday();

    const _extraHeaderData = [
        {
            title: 'row-1',
            date: todayStr,
            doc_type: 'header',
            is_exported: '1',
            uuid: uuidv4(),
            A: 'TIMERHDR',
            B: '8',
            C: '',
            D: 'Chem Clean Resources L.L.C.',
            E: 'N',
            F: 'Y',
            G: '1295928761'
        },
        {
            title: 'string',
            date: todayStr,
            doc_type: 'header',
            is_exported: '1',
            uuid: uuidv4(),
            A: '!HDR',
            B: 'PROD',
            C: 'VER',
            D: 'REL',
            E: 'IIFVER',
            F: 'DATE',
            G: 'TIME'
        },
        {
            title: 'string',
            date: todayStr,
            doc_type: 'header',
            is_exported: '1',
            uuid: uuidv4(),
            A: 'HDR',
            B: 'QuickBooks Pro for Windows',
            C: 'Version 6.0D',
            D: 'Release R14P',
            E: '1',
            F: '',
            G: '1552303449'
        },
        {
            title: 'string',
            date: todayStr,
            doc_type: 'header',
            is_exported: '1',
            uuid: uuidv4(),
            A: '!TIMEACT',
            B: 'DATE',
            C: 'JOB',
            D: 'EMP',
            E: 'ITEM',
            F: 'PITEM',
            G: 'DURATION'
        }
    ];
    const headerAndData = [..._extraHeaderData, ...data];

    var csvData = JSON.stringify(headerAndData)
    csvData = JSON.parse(csvData)

    const classes = useStyles();
    const [order, setOrder] = React.useState<Order>('asc');
    const [orderBy, setOrderBy] = React.useState<keyof Data>('title');
    const [page, setPage] = React.useState(0);
    const [dense, setDense] = React.useState(false);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);

    const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof Data) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleClick = (event: React.MouseEvent<unknown>, s3_key_name: string) => {
    };

    const handleExportClick = async (event: React.FormEvent<HTMLButtonElement>) => {
        console.log('Handle Export Click-------------------')
        data.forEach(function (eachData) {
            exported_ids.push(eachData.uuid)
        })
        while(exported_ids.length>0) {
            const responses = await Axios.post('https://42lq2mie45.execute-api.us-east-1.amazonaws.com/default', {
                exported_ids: exported_ids.splice(0,50)
            })
            if (responses.status == 200) {
                console.log('Update exported_ids successfully.');
            }
        }
    };
    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleChangeDense = (event: React.ChangeEvent<HTMLInputElement>) => {
        setDense(event.target.checked);
    };

    const emptyRows = data.length
        ? rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage)
        : rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage) - 1;

    const s3Url = 'https://time-tracking-2.s3.amazonaws.com';

    return (
        <div className="my-6">
            <h1 className="text-2xl font-semibold mb-6">Table</h1>
            <div className="flex justify-between mb-4">
                <div className="flex space-x-3">
                    {/*<TextField name="s3_key" label="Thumbnail"/>
                    <TextField name="upload_date" label="Upload Date"/>
                    <button className="bg-gray-800 text-white font-semibold rounded-md hover:bg-gray-900 px-3 py-2">
                        Filter
                    </button>*/}
                </div>
                <button className="bg-gray-800 text-white font-semibold rounded-md hover:bg-gray-900 px-3 py-2" style={{ width: "150px", height: "50px", fontSize: "24px" }}
                        onClick={handleExportClick}>
                    <CSVLink data={csvData} headers={csvHeaders}>
                        Export
                    </CSVLink>
                </button>
            </div>
            <div className="border shadow-md hover:shadow-lg transition duration-300 ease-in-out">
                <TableContainer>
                    <Table
                        className={classes.table}
                        aria-labelledby="tableTitle"
                        size={dense ? 'small' : 'medium'}
                        aria-label="enhanced table">
                        <EnhancedTableHead classes={classes} order={order} orderBy={orderBy}
                                           onRequestSort={handleRequestSort}/>
                        <TableBody>
                            {data.length ? (
                                stableSort(data, getComparator(order, orderBy))
                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((row, index) => {
                                        return (
                                            <TableRow
                                                hover
                                                onClick={(event) => handleClick(event, row.title)}
                                                role="checkbox"
                                                tabIndex={-1}
                                                key={index}>
                                                <TableCell align="right">{row.A}</TableCell>
                                                <TableCell align="right">{row.B}</TableCell>
                                                <TableCell align="right">{row.C}</TableCell>
                                                <TableCell align="right">{row.D}</TableCell>
                                                <TableCell align="right">{row.E}</TableCell>
                                                <TableCell align="right">{row.F}</TableCell>
                                                <TableCell align="right">{row.G}</TableCell>
                                            </TableRow>
                                        );
                                    })
                            ) : (
                                <TableRow>
                                    <TableCell align="right">No data</TableCell>
                                </TableRow>
                            )}
                            {emptyRows > 0 && (
                                <TableRow style={{height: (dense ? 33 : 53) * emptyRows}}>
                                    <TableCell colSpan={4}/>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <div className="flex justify-between px-3">
                    <FormControlLabel
                        control={<Switch color="primary" checked={dense} onChange={handleChangeDense}/>}
                        label="Dense table"
                    />
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 20]}
                        component="div"
                        count={data.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onChangePage={handleChangePage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                    />
                </div>
            </div>
        </div>
    );
};
