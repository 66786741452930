import React from 'react';
import {Route, Switch, useRouteMatch} from 'react-router-dom';
import {ItemDetails} from './Details';
import {VerifyingTable} from './VerifyingTable';

export const Verify = () => {
    let {path, url} = useRouteMatch();
    return (
        <Switch>
            <Route exact path={path}>
                <VerifyingTable url={url}/>
            </Route>
            <Route path={`${path}/details/:s3_key`}>
                <ItemDetails/>
            </Route>
        </Switch>
    );
};
