import { TextField } from '@material-ui/core';
import React, {useState} from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import {Auth} from "aws-amplify";

export const Signup = () => {
    const history = useHistory();

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        try {
            const {user} = await Auth.signUp({
                username: email,
                password: password
            });
            console.log(user);
            if (user) {
                alert("Signup Successfull");
                history.push('/dashboard');
            } else {
                alert("Username and password not correct");
            }
        } catch (error) {
            console.log('error signing up:', error);
            alert(error.message)
        }
    };

    return (
        <div className="flex-1 flex flex-col items-center p-12">
            <h1 className="text-2xl font-semibold">Sign up</h1>
            <div className="max-w-lg">
                <form onSubmit={handleSubmit}>
                    <TextField name="email" value={email} onChange={e => setEmail(e.target.value)} label="Email"
                               fullWidth/>
                    <TextField name="password" value={password} onChange={e => setPassword(e.target.value)}
                               label="Password" fullWidth/>
                    <div className="flex justify-between items-center my-6">
                        <button
                            className="bg-gray-800 text-white font-semibold rounded-md hover:bg-gray-900 px-3 py-2"
                            type="submit">
                            Sign up
                        </button>
                        <NavLink exact to="/signin" className="text-gray-800 font-semibold">
                            Already have an account ?
                        </NavLink>
                    </div>
                </form>
            </div>
        </div>
    );
};
