import React, { useEffect, useState } from 'react';
import Axios from 'axios';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import '../../../styles/custom_style.css';

export const Statistics = () => {
  let rows: any = [];
  let extraRows: any = [];
  let cell: any = [];
  let extraCell: any = [];
  var tHeaders = ['Month', 'Kinder Morgan', 'LBC', 'Other'];

  tHeaders.forEach(function(eachHeader) {
    cell.push(<th className={'MuiTableCell-root MuiTableCell-body'}>{eachHeader}</th>);
  });
  rows.push(<tr className={'MuiTableRow-root'}>{cell}</tr>);

  const [extraRowData, setExtraRowData] = useState<[]>([]);
  const ApiCall = async () => {
    const responses = await Axios.get('https://624chde2rb.execute-api.us-east-1.amazonaws.com/default');
    if (responses.status == 200) {
      let parseData = JSON.parse(responses.data.body);
      console.log(parseData);

      for (var key of Object.keys(parseData)) {
        let hour_list = parseData[key];

        // hour_list=hour_list.split(",")

        extraCell.push(<td className={'MuiTableCell-root MuiTableCell-body'}>{key}</td>);

        hour_list.forEach(function(eachDataHeader: any) {
          extraCell.push(<td className={'MuiTableCell-root MuiTableCell-body'}>{eachDataHeader}</td>);
        });

        extraRows.push(<tr className={'MuiTableRow-root'}>{extraCell}</tr>);

        extraCell = [];
      }
      setExtraRowData(extraRows);
    }
  };
  useEffect(() => {
    ApiCall();
  }, []);
  return (
    <>
      <TableContainer>
        <Table>
          <TableBody>
          </TableBody>
        </Table>
      </TableContainer>
      <table id='simple-board' className={'MuiTable-root jss101'}>
        <tbody className={'MuiTableBody-root'}>
        {rows}
        {extraRowData}
        </tbody>
      </table>
    </>
  );
};
