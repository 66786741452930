import { TextField } from '@material-ui/core';
import React , {useState} from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import { Auth } from "aws-amplify";

export const Signin = () => {
    const history = useHistory();

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        try {
            await Auth.signIn(email, password);
            const user = await Auth.signIn(email, password);
            console.log(user)
            if (user) {
                alert("Logged in");
                history.push('/dashboard');
            } else {
                alert("Username and password not correct");
            }
        } catch (e) {
            alert(e.message);
        }
    };


    return (
        <div className="flex-1 flex flex-col items-center p-12">
            <h1 className="text-2xl font-semibold">Sign in</h1>
            <div className="max-w-lg">
                <form onSubmit={handleSubmit}>
                    <TextField type="username" value={email} onChange={e => setEmail(e.target.value)} label="Email"
                               fullWidth/>
                    <TextField type="password" value={password} onChange={e => setPassword(e.target.value)}
                               label="Password" fullWidth/>
                    <div className="flex justify-between items-center my-6">
                        <button className="bg-gray-800 text-white font-semibold rounded-md hover:bg-gray-900 px-3 py-2">
                            Sign in
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};
