import Axios from 'axios';
import React, {useEffect, useState} from 'react';
import {ExportingTable} from './ExportingTable';

interface IExportData {
    title: string;
    date: string;
    doc_type: string;
    is_exported: string;
    uuid: string;
    A: string;
    B: string;
    C: string;
    D: string;
    E: string;
    F: string;
    G: string;
}

export const Export = () => {
    const [data, setData] = useState<IExportData[]>([]);
    const ApiCall = async () => {
        const responses = await Axios.get('https://a2h9qlq3ci.execute-api.us-east-1.amazonaws.com/default/GetExportItems')

        let _Data = [{}] as IExportData[]

        if (responses.status == 200) {
            const parseData = JSON.parse(responses.data.Items)
            console.log(parseData);

            _Data = parseData.Items as IExportData[]
            setData(_Data)
        }
    }
    useEffect(() => {
        ApiCall();
    }, []);

    return <ExportingTable data={data}/>;
};
