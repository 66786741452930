import React from 'react';

export const Home = () => {
  return (
    <div className="flex-1 flex flex-col space-y-10 items-center text-justify p-12">
      <h1 className="text-4xl font-semibold">CRT</h1>
      <div>
          <img src='/logo.jpg'/>
      </div>
    </div>
  );
};
