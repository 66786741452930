import {InputLabel, Select, TextField} from '@material-ui/core';
import React, {useState} from 'react';
import {Storage} from 'aws-amplify';
import Axios from "axios";
import { useHistory } from 'react-router-dom';

interface TimeTrackingUploadFileHistory {
    is_exported: string,
    is_extracted: string,
    s3_key_name: string,
    extracted_date?: string,
    doc_type: string,
    id?: string,
    is_verified: string,
    s3_key: string,
    title: string,
    upload_date: string
}

export const Upload = () => {
    const [title, setTitle] = useState<string>('');
    const [files, setFiles] = useState<FileList>();
    const [docType, setDocType] = useState<string>();
    const history = useHistory();

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {

        event.preventDefault()

        if (!event.target.files) {

            alert('Please attach some files.');

        } else {

            for (var i = 0; i < event.target.files.length; i++) {
                setFiles(event.target.files);
            }
        }
    };

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        var _docType = docType as string
        var timeTrackingData: TimeTrackingUploadFileHistory[] = []

        if (!files) {
            alert('Please attach some files.');
        } else if (docType === undefined) {
            alert('Please select a doc type.');
        } else {
            console.log(files.length);
            console.log(docType)

            for (var i = 0; i < files.length; i++) {
                const dt = new Date();
                // var dateTime = d.getFullYear() + "-" + (d.getMonth() + 1) + "-" + d.getDate() + "-" + d.getHours() + "-" + d.getMinutes() + "-" + d.getSeconds();
                var dateTime = Date.now()
                var filename = files[i].name;
                const ext = filename.split(".")[1];

                const s3_key = dateTime + "_" + i + "__" + docType + "." + ext;
                const s3_key_name = s3_key.split(".")[0];


                console.log(dateTime)
                console.log(filename)
                console.log(title)
                console.log(s3_key)
                await Storage.put(s3_key, files[i], {
                    contentType: 'application/pdf',
                    customPrefix: {public: ''}
                }).then(result => {
                    console.log(result)
                    timeTrackingData.push({
                            is_exported: '0',
                            is_verified: '0',
                            is_extracted: '0',
                            s3_key_name: s3_key_name,
                            doc_type: _docType,
                            s3_key: s3_key,
                            title: title,
                            upload_date: dt + ""
                        }
                    )
                }).catch(err => {
                    console.log(err)
                    alert(`Can't upload file: ${err}`)
                });
            }
            // alert("timeTrackingData.length: " + timeTrackingData.length)
            if (timeTrackingData.length > 0) {
                const timeTrackingDataAsJson = JSON.parse(JSON.stringify(timeTrackingData))
                console.log(timeTrackingDataAsJson)
                const responses = await Axios.post('https://70fwheuqxe.execute-api.us-east-1.amazonaws.com/default', {
                    time_tracking_data: timeTrackingDataAsJson
                })
                if (responses.status == 200) {
                    alert('Uploaded successfully.');
                    history.push('/dashboard/verify-items');
                }
            }
        }
    };
    return (
        <div className="flex flex-col items-center">
            <h1 className="text-2xl font-semibold">Upload form</h1>
            <div className="max-w-lg">
                <form onSubmit={handleSubmit}>
                    <TextField
                        name="title"
                        label="Title"
                        fullWidth
                        required
                        value={title}
                        onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) =>
                            setTitle(event.target.value)
                        }
                    />
                    <div className="flex flex-col my-3">
                        {files && (
                            <div>
                                <h6 className="font-semibold text-gray-800">Files</h6>
                                {Object.keys(files).map((key) => (
                                    <div key={key}
                                         className="border-2 border-gray-500 rounded text-center py-1 font-semibold">
                                        {files[Number(key)].name}
                                    </div>
                                ))}
                            </div>
                        )}
                        <label htmlFor="browse-file-button">
                            <div
                                className="w-48 text-center border-2 border-gray-800 cursor-pointer font-semibold mt-2 py-2 rounded text-gray-800 hover:bg-gray-800 hover:text-white transition duration-300 ease-in-out">
                                <span>Upload files</span>
                            </div>
                        </label>
                    </div>

                    <input
                        multiple
                        accept="application/pdf"
                        className="hidden"
                        id="browse-file-button"
                        type="file"
                        onChange={handleFileChange}/>
                    <InputLabel htmlFor="age-native-simple">Doc type</InputLabel>
                    <Select
                        native
                        value={docType}
                        onChange={event => setDocType(event.target.value as string)}
                        inputProps={{
                            name: 'Doc type',
                            id: 'doc-type-native-simple',
                        }}>
                        <option aria-label="None" value=""/>
                        <option value={'shop_ticket'}>Shop Ticket</option>
                        <option value={'time_tracking'}>Time Sheet</option>
                        <option value={'vacation'}>Vacation</option>
                        <option value={'safety_meeting'}>Safety Meeting</option>
                        <option value={'weekly_time_report'}>Weekly TimeReport</option>
                    </Select>
                    <div className="flex justify-between items-center my-6">
                        <button type="submit"
                                className="bg-gray-800 text-white font-semibold rounded-md hover:bg-gray-900 px-3 py-2">
                            Upload
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};
