import React, {useEffect, useState} from 'react';
import {NavLink, useHistory, useLocation} from 'react-router-dom';
import Auth from '@aws-amplify/auth';

export const Navbar = () => {
    const history = useHistory();
    const [isLogin, setIsLogin] = useState<boolean>(false);
    const checkIsLoggedIn = async () => {
        const currentRoute = window.location.pathname.split('/')[1]
        try {
            console.log('user: ================= ')
            Auth.currentAuthenticatedUser({
                bypassCache: false  // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
            }).then(function (user) {
                console.log(user)
                if (user) {
                    setIsLogin(true)
                    if (currentRoute !== 'dashboard') {
                        history.push('/dashboard');
                    }
                } else {
                    setIsLogin(false)
                    if (currentRoute !== '') {
                        history.push('/signin');
                    }
                }
            }).catch(function (err) {
                console.log(err)
                setIsLogin(false)
                if (currentRoute !== '') {
                    history.push('/signin');
                }
            });
        } catch {
            setIsLogin(false)
            if (currentRoute !== '') {
                history.push('/signin');
            }
        }
    }
    // TODO: Use a logic for Global State
    useEffect(() => {
        checkIsLoggedIn();
    }, [window.location.pathname]);

    async function signOut() {
        try {
            await Auth.signOut({global: true});
            setIsLogin(false);
            history.push('/signin');
        } catch (error) {
            console.log('error signing out: ', error);
        }
    }

    return (
        <header className="flex justify-between items-center bg-gray-800 px-3 py-3">
            <NavLink exact to="/">
                <img src='/logo.jpg' width={48}/>
            </NavLink>
            <div className="flex justify-between items-center space-x-2">
                {isLogin ? (
                    <button className="bg-gray-300 font-semibold px-3 py-2 rounded-md hover:bg-gray-400"
                            onClick={signOut}>
                        Logout
                    </button>
                ) : (
                    <>
                        <NavLink exact to="/signin" className="block">
                            <button className="bg-gray-300 font-semibold px-3 py-2 rounded-md hover:bg-gray-400">
                                Sign in
                            </button>
                        </NavLink>
                    </>
                )}
            </div>
        </header>
    );
};
