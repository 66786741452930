import React, {useEffect, useState} from 'react';
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import {Link, useRouteMatch} from 'react-router-dom';
import Axios from "axios";
import { Document, Page } from 'react-pdf';

interface Data {
    title: string;
    doc_type: string;
    s3_key: string;
}


function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

type Order = 'asc' | 'desc';

function getComparator<Key extends keyof any>(
    order: Order,
    orderBy: Key,
): (a: { [key in Key]: number | string }, b: { [key in Key]: number | string }) => number {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
    const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

interface HeadCell {
    disablePadding: boolean;
    id: keyof Data;
    label: string;
    numeric: boolean;
}

const headCells: HeadCell[] = [
    {id: 'title', numeric: true, disablePadding: false, label: 'Title'},
    {id: 'doc_type', numeric: true, disablePadding: false, label: 'Doc Type'},
    {id: 's3_key', numeric: true, disablePadding: false, label: 'Thumbnail'},
];

interface EnhancedTableHeadProps {
    classes: ReturnType<typeof useStyles>;
    onRequestSort: (event: React.MouseEvent<unknown>, property: keyof Data) => void;
    order: Order;
    orderBy: string;
}

function EnhancedTableHead(props: EnhancedTableHeadProps) {
    const {classes, order, orderBy, onRequestSort} = props;
    const createSortHandler = (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
        onRequestSort(event, property);
    };


    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? 'right' : 'left'}
                        padding={'default'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
                <TableCell key={2} align="right" padding="default">
                    Action
                </TableCell>
            </TableRow>
        </TableHead>
    );
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        table: {
            minWidth: 750,
        },
        visuallyHidden: {
            border: 0,
            clip: 'rect(0 0 0 0)',
            height: 1,
            margin: -1,
            overflow: 'hidden',
            padding: 0,
            position: 'absolute',
            top: 20,
            width: 1,
        },
    }),
);

interface EnhancedTableProps {
    url: string;
}

interface ITableData {

    title: string;
    doc_type: string;
    s3_key: string;

}

export const VerifyingTable = (props: EnhancedTableProps) => {
    const {url} = props;
    const classes = useStyles();
    const [order, setOrder] = React.useState<Order>('asc');
    const [orderBy, setOrderBy] = React.useState<keyof Data>('doc_type');
    const [page, setPage] = React.useState(0);
    const [dense, setDense] = React.useState(false);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);

    const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof Data) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleClick = (event: React.MouseEvent<unknown>, name: string) => {
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleChangeDense = (event: React.ChangeEvent<HTMLInputElement>) => {
        setDense(event.target.checked);
    };

    let {path} = useRouteMatch();
    const [data, setData] = useState<ITableData[]>([]);

    const emptyRows = data.length
        ? rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage)
        : rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage) - 1;

    const s3Url = 'https://time-tracking-2.s3.amazonaws.com';

    const Apicall = async () => {

        const responses = await Axios.get('https://7524vw8eci.execute-api.us-east-1.amazonaws.com/default')

        const parseData = JSON.parse(responses.data.Items)
        console.log(parseData);

        const _data = parseData.Items as ITableData[]

        const mappedData = _data.map((i: any) => {
            return {doc_type: i.doc_type, s3_key: i.s3_key, title: i.title}
        })

        setData(mappedData)
    }

    useEffect(() => {

        Apicall();

    }, []);


    return (
        <div className="my-6">
            <h1 className="text-2xl font-semibold">Table</h1>
            <div className="border shadow-md hover:shadow-lg transition duration-300 ease-in-out">
                <TableContainer>
                    <Table
                        className={classes.table}
                        aria-labelledby="tableTitle"
                        size={dense ? 'small' : 'medium'}
                        aria-label="enhanced table"
                    >
                        <EnhancedTableHead classes={classes} order={order} orderBy={orderBy}
                                           onRequestSort={handleRequestSort}/>
                        <TableBody>
                            {data.length ? (
                                stableSort(data, getComparator(order, orderBy))
                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((row, index) => {
                                        const labelId = `enhanced-table-checkbox-${index}`;

                                        return (
                                            <TableRow
                                                hover
                                                onClick={(event) => handleClick(event, row.doc_type)}
                                                role="checkbox"
                                                tabIndex={-1}
                                                key={index}>
                                                <TableCell align="right" component="th" id={labelId} scope="row"
                                                           padding="none">
                                                    {row.title}
                                                </TableCell>
                                                <TableCell align="right" component="th" id={labelId} scope="row"
                                                           padding="none">
                                                    {row.doc_type}
                                                </TableCell>
                                                <TableCell align="right">
                                                    <div className="flex justify-end h-20">
                                                    <embed src={`${s3Url}/${row.s3_key}`} title={row.doc_type} height="90" width="100" />
                                                    </div>
                                                </TableCell>
                                                <TableCell align="right">
                                                    <Link to={`${url}/details/${row.s3_key}`}>
                                                        <button
                                                            className="bg-gray-800 text-white font-semibold rounded-md hover:bg-gray-900 px-3 py-2">
                                                            Check
                                                        </button>
                                                    </Link>
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })
                            ) : (
                                <TableRow>
                                    <TableCell align="right">No data</TableCell>
                                </TableRow>
                            )}
                            {emptyRows > 0 && (
                                <TableRow style={{height: (dense ? 33 : 53) * emptyRows}}>
                                    <TableCell colSpan={3}/>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <div className="flex justify-between px-3">
                    <FormControlLabel
                        control={<Switch color="primary" checked={dense} onChange={handleChangeDense}/>}
                        label="Dense table"
                    />
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 20]}
                        component="div"
                        count={data.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onChangePage={handleChangePage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                    />
                </div>
            </div>
        </div>
    );
};
